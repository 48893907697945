import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Branch extends BaseModel {
    fields =  {
      'name': {
        type: 'text',
        label: 'nama',
        rules: 'required'
      },
      'provincy_id': {
        label: 'Provinsi',
        type: 'enum',
        options: [],
        rules: 'required'
      },
      'regency_id': {
        label: 'Kota',
        type: 'enum',
        options: [],
        rules: 'required'
      },
      'address': {
        type: 'text',
        label: 'Alamat',
        rules: 'required'
      },
      'phone': {
        type: 'text',
        rules: 'required'
      },
      'whatsapp': {
        type: 'text',
        rules: 'required'
      },
      'email': {
        type: 'text',
        rules: 'required|email'
      },
      'type': {
        label: 'Tipe Cabang',
        type: 'enum',
        options: {
          'Kantor Pusat': 'Kantor Pusat',
          'Cabang': 'Cabang',
          'Coming Soon': 'Coming Soon'
        },
        rules: 'required'
      },
      'is_active': {
        label: 'Status',
        type: 'enum',
        options: {
          0: 'Tidak Aktif',
          1: 'Aktif'
        },
        rules: 'required'
      }
    }
    endpoint = process.env.VUE_APP_API_URL + 'branches'
}